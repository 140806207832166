<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Dreamers')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>

    <!-- FORM DE DREAMER -->
    <add-new-dreamer
      v-if="isAddNewDreamerSidebarActive"
      :groups="own_groups"
      :nee="nee"
      :topics="topics"
      :is-add-new-dreamer-sidebar-active.sync="isAddNewDreamerSidebarActive"
      @refetch-data="getData"
    />

    <modal-personalize
      :isGroup="false"
      :selected_data="selDreamersData"
      @personalize_modal_active="personalize_modal_active = false"
    />
    <modal-content-restriction
      :isGroup="false"
      :selected_data="selDreamersData"
      @content_restriction_modal_active="content_restriction_modal_active = false"
    />

    <modal-change-group
      :groups="own_groups_with_no_group"
      :selected_data="selDreamersData"
      @refetch-data="getData"
    />

    <modal-children-passwords
      :isGroup="false"
      :selected_data="selDreamersData"
      @password_modal_active="password_modal_active = false"
    />
    
    <modal-massive
      from="grupos"
    ></modal-massive>

    <b-sidebar v-if="sidebar_lp_dreamer_id"
      :key="sidebar_lp_dreamer_id"
      :id="'sidebar-right-' + sidebar_lp_dreamer_id"
      bg-variant="white"
      right
      backdrop
      shadow
      @hidden="hideLPSidebar"
      :visible="showSidebar"
      style="z-index: 2000"
    >
      <sidebar-assigned-routes @hide="hideLPSidebar" :dreamerId="sidebar_lp_dreamer_id"/>
    </b-sidebar>
    
    <b-card>
      <filters
        ref="filter"
        context="user-dreamers"
        :groups-options="groupsOptions"
        :nee="nee"
        :age-range-options="age_range_options"
        @set-data="setData"
      /> 
    </b-card>

    <!-- Buttons -->
    <b-row v-if="!loading_data">
      <b-col cols="6">
        <b-button
          v-b-tooltip.hover.top="$store.getters['general/getTableView']?$t('layout.change_to_grid_view'):$t('layout.change_to_table_view')"
          variant="primary"
          type="button"
          class="mr-1 mb-1"
          @click="changeView()"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <feather-icon
            :icon="$store.getters['general/getTableView']?'GridIcon':'ListIcon'"
            size="16"
          />
        </b-button>
        <b-form-checkbox
          v-if="!($store.getters['general/getTableView'])&&dreamers.length>0"
          v-model="allSelected"
          @change="manageSelectAll()"
          inline
        >
          {{ $t('table.select_all')}}
        </b-form-checkbox>
      </b-col>
      <b-col cols="6" style="text-align:right">
        <b-button
          v-for="button in buttonsOptions"
          :disabled="button.disabled"
          :key="button.action"
          v-b-tooltip.hover.top="button.name"
          :variant="button.variant||'primary'"
          type="button"
          class="ml-1 mb-1"
          @click="handle(button.action)"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <span v-if="button.msg">{{$t(button.msg)}}</span>
          <feather-icon v-else
            :icon="button.icon"
            size="16"
          />
        </b-button>
      </b-col>

    </b-row>
    <!-- TABLA -->
    <b-card v-if="$store.getters['general/getTableView']" no-body>
      <paginated-table
        :data="dreamers"
        ability="Dreamers"
        link='dreamer'
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        :canRecover="false"
        :canDownload="false"
        :loading_data="loading_data"     

        @personalize="showPersonalizeModal"
        @content-restriction="showContentRestrictionModal"
        @passwords="showPasswordsModal"
        @mostrar-sidebar="showLPSidebar"


        @unlinkSharedDreamer="unlinkSharedDreamer"


      ></paginated-table>
    </b-card>
    <!-- Dreamer Cards -->
    
    <b-row v-else class="match-height">
      <b-col v-if="loading_data" cols="12">
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
      <template v-else>
      <b-col
        lg="3"
        md="6"
        v-for="dreamer in dreamers"
        :key="dreamer.id"
      >
        <avatar-card 
          :item="dreamer"
          :dropdown_options="dropdown_options(dreamer.owner)"
          context="dreamer"
          :selected_items="selected_dreamers"
          @item-selected="itemSelected"
          @mostrar-sidebar="showLPSidebar"

          @details="$router.push({ name: 'dreamer-view', params: { id: dreamer.id  } })"
          @edit="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id } })"
          @personalize="showPersonalizeModal"
          @content-restriction="showContentRestrictionModal"

          @passwords="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id, tab:1  } })"
          @share="$router.push({ name: 'dreamer-edit', params: { id: dreamer.id, tab:4  } })"

          @delete="deleteModel(dreamer.id)"          
          @unlinkSharedDreamer="unlinkSharedDreamer(dreamer.id)"
        />
      </b-col>      
    </template>  
    </b-row>
  </div>
    
</template>

<script>
import { unlinkShared, searchDreamers, exportGeneral, deleteDreamer, deleteDreamersMasive } from '@/api/routes'
import SidebarAssignedRoutes from '@/views/learning-paths/components/SidebarAssignedRoutes.vue'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import AvatarCard from '@/views/sl-components/AvatarCard.vue'
import Filters from '@/views/sl-components/Filters.vue'

import AddNewDreamer from '../dreamers/AddNew.vue'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
  BDropdown, BDropdownItem, BDropdownDivider,
  BNav, BNavItem, BNavItemDropdown,
  BFormCheckbox, BSpinner,
  BFormGroup, BFormInput, BForm, BFormRadio,
  BBreadcrumb, BBreadcrumbItem, BSidebar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalPersonalize from '../modals/ModalPersonalize.vue'
import ModalContentRestriction from '../modals/ModalContentRestriction.vue'
import ModalChangeGroup from '../modals/ModalChangeGroup.vue'
import ModalChildrenPasswords from '../modals/ModalChildrenPasswords.vue'
import ModalMassive from '../modals/ModalMassive.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    SidebarAssignedRoutes,
    BSidebar,
    AvatarCard,
    CustomBreadcrumb,
    AddNewDreamer,
    Filters,
    PaginatedTable,
    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
    ModalMassive,
    ModalChangeGroup,

    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
    BDropdown, BDropdownItem, BDropdownDivider,
    BNav, BNavItem, BNavItemDropdown,
    BFormCheckbox, BSpinner,
    BFormGroup, BFormInput, BForm, BFormRadio,
    BBreadcrumb, BBreadcrumbItem, 
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading_data: true, 

      isAddNewDreamerSidebarActive: false, 

      nee:[],
      topics:[],
      groups:[],
      own_groups:[],
      own_groups_with_no_group: [],
      dreamers:[],
      owned_dreamers:0,
      
      selected_dreamers: [],
    
      allSelected: false,

      dreamer:null,
      password_modal_active: false,
      content_restriction_modal_active: false,
      personalize_modal_active: false, 

      showSidebar:false,
      sidebar_lp_dreamer_id: null
    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    selDreamersData(){
      if((this.personalize_modal_active ||this.content_restriction_modal_active ||this.password_modal_active) && this.dreamer){
        let data = {
          ids: [this.dreamer.id],
          info: [{
            id: this.dreamer.id,
            name: this.dreamer.name,
            avatar: this.dreamer.avatar || '57',
          }]
        }
        return data
      } else {
        //let d = this.selected_dreamers.slice(0, 5).map(x => this.dreamers.filter(e=> e.id == x))
        let d = this.selected_dreamers.map(x => this.dreamers.filter(e=> e.id == x))
        let clean_d = d.map(x=>{ return{'id': x[0].id, 'name': x[0].name, 'avatar': x[0].avatar, 'owner': x[0].owner}})
        let data = {
          ids: this.selected_dreamers,
          info: clean_d
        }
        return data
      }
    },
    //options para filtro
    groupsOptions(){
      let newGroupsOptions = []
      this.groups.forEach(x => {
        let can_select = true;
        newGroupsOptions.push(
            { 'value': x.id, 'label': x.name, 'can_select': can_select }
        ) 
      });
      return newGroupsOptions;
    },
    
    age_range_options(){
      return [
        { 'value': '3-6', 'label': '3-6', 'can_select': true },
        { 'value': '6-7', 'label': '6-7', 'can_select': true },
        { 'value': '7-8', 'label': '7-8', 'can_select': true },
        { 'value': '8-9', 'label': '8-9', 'can_select': true },
        { 'value': '9-10', 'label': '9-10', 'can_select': true },
        { 'value': '10-11', 'label': '10-11', 'can_select': true },
        { 'value': '11-12', 'label': '11-12', 'can_select': true },
      ]
    },
    buttonsOptions(){
      let userData = decryptData(localStorage.getItem('userData'))
      let can_create_new_dreamers = false
      if(userData.user_max_children == 0){
        //unlimited dreamers
        can_create_new_dreamers = true
      } else {
        if(this.owned_dreamers < userData.user_max_children){
          can_create_new_dreamers = true
        }
      }

        let opt = []
          opt.push({
            action: 'add-dreamer',
            icon: "PlusIcon",
            variant: !can_create_new_dreamers? "secondary":"success",
            name: this.$t('forms.add_model', {model: this.$t('models.dreamer')}),
            disabled: !can_create_new_dreamers,
            msg:!can_create_new_dreamers ? 'message.ERR_DREAMER_1003' : null
          })
      
        // if(this.dreamers.length>0){
        //   opt.push({
        //       action: "exportToExcel",
        //       icon: "DownloadIcon",
        //       name: this.$t('table.export_to_excel'),
        //     }) 
        // }
        if(this.selected_dreamers.length > 0){
          opt.push(
            {
              action: "customizeDreamers",
              icon: "SlidersIcon",
              name: this.$t('customize.customize'),
            }, 
            {
              action: "contentRestriction",
              icon: "ClockIcon",
              name: this.$t('customize.mandatory'),
            }, 
            {
              action: "changeGroup",
              icon: "LogInIcon",
              name: this.$t('customize.change_group'),
            }, 
            {
              action: 'assignPasswords',
              name: this.$t('children_passwords.edit_children_passwords'),
              icon: "UnlockIcon"
            },
            {
              action: "deleteSelection",
              icon: "Trash2Icon",
              name: this.$t('table.delete_selection'),
              variant: "danger",
            }
          )
        }
        return opt
    },
    breadcrumb(){
      return [
        {
          text: this.$t('Dreamers'),
          active: true,
        },
      ]
    },
  },
  methods:{
    hideLPSidebar() {
      this.showSidebar = false
    },
    showLPSidebar(id){
      this.sidebar_lp_dreamer_id = id
      this.showSidebar = true
    },
    changeView(){
      /*
       * Unselect dreamers when view changes
       *
       * (Si queremos que la seleccion se mantenga, solo funciona de table a grid. 
       * Si seleccionamos en grid y pasamos a tabla, la tabla no recibe los seleccionados.) 
       */
      this.$store.commit('general/setTableView');
      this.selected_dreamers = []
      this.allSelected = false
    },
    manageSelectedRows(selected_rows){
      let ids_only = selected_rows.map(x => x.id)
      this.selected_dreamers = ids_only
    },
    showPersonalizeModal(dreamer){
      this.personalize_modal_active = true
      this.dreamer = dreamer
      this.$bvModal.show('modal-personalize')
    },
    showContentRestrictionModal(dreamer){
      this.content_restriction_modal_active = true
      this.dreamer = dreamer
      this.$bvModal.show('modal-content-restriction')
    },
    showPasswordsModal(dreamer){
      this.password_modal_active = true
      this.dreamer = dreamer
      this.$bvModal.show('modal-assign-pass')
    },
    dropdown_options(isOwner){
      let options = [
        {
          action: "details",
          color: null,
          title: this.$t('table.details'),
          icon: "FileTextIcon",
        }, 
        {
          action: 'edit',
          color: null,
          title: this.$t('forms.edit_model', {model: this.$t('models.dreamer')}),
          icon: "EditIcon"
        },
        {
          action: 'personalize',
          color: null,
          title: this.$t('customize.customize'),
          icon: "SettingsIcon"
        },
        {
          action: 'content-restriction',
          color: null,
          title: this.$t('customize.mandatory'),
          icon: "ClockIcon"
        },
        {
          action: 'passwords',
          color: null,
          title: this.$t('settings.change_pin'),
          icon: "UnlockIcon"
        },
        

      ]
      if(isOwner){
        options.push({
          action: 'share',
          color: null,
          title: this.$t('general.share'),
          icon: "Share2Icon"
        })
        options.push({
          action: 'delete',
          color: 'red',
          title: this.$t('forms.delete'),
          icon: "TrashIcon"
        })
      } else {
        options.push({
          action: 'unlinkSharedDreamer',
          color: 'red',
          title: this.$t('dreamer.unlink_shared_dreamer'),
          icon: "XIcon"
        })
      }
      return options
    },
    setData(data){
        if(data.dreamers.length > 40000){
            this.dreamers = []
            this.loading_data = false 
        } else {
            this.loading_data = false 
            this.dreamers = data.dreamers;
        }
        this.allSelected = false
        this.manageSelectAll()
    },
    manageSelectAll(){
      if(this.allSelected){
        this.selected_dreamers = this.dreamers.map( i => i.id)
      } else {
        this.selected_dreamers = []
      }
    },
    unlinkSharedDreamer(dreamer_id){
      this.$bvModal
        .msgBoxConfirm(this.$t('dreamer.unlink_shared_dreamer_confirmation'), {
          title: this.$t('dreamer.unlink_shared_dreamer'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              id: dreamer_id,
              context: 'dreamer'
            }
            this.$http.post(unlinkShared, data).then( response =>{
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('message.'+response.data.msg_code));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    async getData(){
        this.loading_data = true
        let data = {
          'group': null,
          'nee': null,
          'age_range': null,
          'searchword': ''
        }
        await this.$http.post(searchDreamers, data).then( response =>{
          if(response.status == 200){
            this.nee = response.data.nee;
            this.topics = response.data.topics;
            this.dreamers = response.data.dreamers;
            this.groups = response.data.groups;
            let userData = this.$store.getters['user/getUserData'];
            let own_g = []
            let own_g_and_no_group = []
            this.groups.map(g=>{
              if(g.id != 0){
                if(g.id_user == userData.id){
                  own_g.push(g)
                  own_g_and_no_group.push(g)
                }
              } else {
                g.name = this.$t('table.no_group')
                own_g_and_no_group.push(g)
              }
            })
            this.own_groups = own_g;
            this.own_groups_with_no_group = own_g_and_no_group;

            let own_d = response.data.dreamers.filter(d => d.owner)
            this.owned_dreamers = own_d.length
            this.loading_data = false 
          } else {
            this.loading_data = false
            this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.code_msg));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.loading_data = false 
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        })
    },
    itemSelected(item){
      var index = this.selected_dreamers.indexOf(item.id);
      if (index !== -1) {
        this.selected_dreamers.splice(index, 1);
      } else {
        this.selected_dreamers.push(item.id)
      }  

      if(this.selected_dreamers.length == this.dreamers.length){
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
    handle(action){
      switch (action) {
        case 'add-dreamer':
          this.isAddNewDreamerSidebarActive = true
          break;
        case 'customizeDreamers':
          this.$bvModal.show('modal-personalize')
          break;
        case 'contentRestriction':
          this.$bvModal.show('modal-content-restriction')
          break;
        case 'changeGroup':
          this.$bvModal.show('modal-change-group')
          break;
        case 'assignPasswords':
          this.$bvModal.show('modal-assign-pass')
          break;
        case 'exportToExcel':
          this.download()
          break;
        case 'deleteSelection':
          this.deleteSelection()
          break;
        default:
          break;
      }
    },
    async download(){
      let rows_ids = this.selected_dreamers.length > 0 ? this.selected_dreamers : this.dreamers.map(x => x.id)
      let data = {
        rows_ids : rows_ids,
        context : 'school-dreamers',
      }
      await this.$http.post(exportGeneral, data, {responseType:'blob'}).then( response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Dreamers.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_dreamer_confirmation'), {
          title: this.$t('forms.delete_model', {model: this.$t('models.dreamer')}),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$http.delete(deleteDreamer+'/'+id).then( response =>{
              if(response.data.status == 200){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }
            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    deleteSelection(){
      let dreamers_data = this.selDreamersData
      let dreamers_array = dreamers_data.info.filter(x=> x.owner).map(d=>d.id)

      if(dreamers_array.length == 0){
        this.$bvModal
        .msgBoxOk(this.$t('table.dreamers_cannot_be_deleted'), {
          title: this.$t('Warning'),
          size: 'sm',
          variant: 'warning',
          okTitle: this.$t('Understood'),
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        const h = this.$createElement
        const messageVNode = h('div', {}, [
          h('p',{}, [
            this.$t('table.delete_selection_confirmation', {num: dreamers_array.length}),
            ' ',
            h('strong', this.$t('table.dreamers_cannot_be_deleted')),
          ]),
          h('p', {}, this.$t('table.deactivate_dreamers_recover')),
          
        ])
        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('table.delete_selection'),
            cancelTitle: this.$t('forms.cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'dreamers':   dreamers_array
              }
              this.$http.post(deleteDreamersMasive, data).then( response =>{
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }
                this.selected= []

              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>
#my-nav-dropdown{
    list-style-type:none;
}


</style>
